/* FontAwesome SVG + JS subsetting to reduce load

   Make sure you add the icon to the appropriate import list AND
   to the library list

*/

import { config, dom, library } from "@fortawesome/fontawesome-svg-core"
import {
  faBehance,
  faBloggerB,
  faDribbble,
  faDropbox,
  faFacebookF,
  faGithub,
  faHubspot,
  faInstagram,
  faLinkedinIn,
  faMediumM,
  faPinterest,
  faSnapchatGhost,
  faTwitter,
  faVimeoV,
  faWordpressSimple,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import {
  faCircleCheck as faCircleCheckDuo,
  faCircleExclamation as faCircleExclamationDuo,
  faCircleInfo as faCircleInfoDuo,
  faCircleMinus as faCircleMinusDuo,
  faCircleXmark as faCircleXmarkDuo,
  faTriangleExclamation as faTriangleExclamationDuo,
} from "@fortawesome/pro-duotone-svg-icons"
import {
  faClone as faCloneLight,
  faDownload as faDownloadLight,
  faEllipsis,
  faRectangleHistoryCirclePlus,
  faStar as faStarLight,
  faUserPlus as faUserPlusLight,
  faXmark as faXmarkLight,
} from "@fortawesome/pro-light-svg-icons"
import {
  faAnalytics as faAnalytics2,
  faAngleLeft,
  faArrowDown,
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowUp,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowUpRightFromSquare,
  faBahtSign,
  faBan,
  faBars,
  faBolt,
  faBoltSlash,
  faBoxArchive,
  faBrazilianRealSign,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faChairOffice,
  faChartBar,
  faChartPie,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faChevronUp,
  faChfSign,
  faCircleCheck as faCircleCheckRegular,
  faCircleExclamation as faCircleExclamationRegular,
  faCircleInfo as faCircleInfoRegular,
  faClipboardCheck,
  faClock,
  faClone,
  faCodeFork,
  faCodeMerge,
  faComment,
  faComments,
  faCompass,
  faDesktop,
  faDollarSign,
  faDownload,
  faDropletSlash,
  faEdit,
  faEllipsis as faEllipsisRegular,
  faEllipsisVertical,
  faEnvelope,
  faEuroSign,
  faEye,
  faEyeSlash,
  faFile,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFillDrip,
  faFilter,
  faFlag,
  faFolderTree,
  faGear,
  faHashtag,
  faHeart,
  faHistory,
  faHome,
  faICursor,
  faIdBadge,
  faIndianRupeeSign,
  faKey,
  faLayerGroup,
  faListOl,
  faListTree,
  faListUl,
  faLockKeyhole,
  faLongArrowAltRight,
  faMessageLines,
  faMinus,
  faMoneyBill,
  faPalette,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencilRuler,
  faPercent,
  faPesoSign,
  faPhone,
  faPlus,
  faPoundSign,
  faPrint,
  faR,
  faRedoAlt,
  faRight,
  faRotate,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShareSquare,
  faSignature,
  faSignIn,
  faSignOut,
  faSitemap,
  faSliders,
  faSparkles,
  faSquareCheck,
  faSquarePlus,
  faStickyNote,
  faStopwatch,
  faTrashAlt,
  faTriangleExclamation as faTriangleExclamationRegular,
  faTurkishLira,
  faUndo,
  faUndoAlt,
  faUpload,
  faUsdSquare,
  faUser,
  faUserPlus,
  faUsers,
  faXmark,
  faYenSign,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faAnalytics,
  faArrowAltRight as faArrowAltRightSolid,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCircleXmark,
  faComment as faCommentSolid,
  faEraser,
  faExclamation,
  faEye as faEyeSolid,
  faGripDotsVertical,
  faLink,
  faLowVision,
  faPhone as faPhoneSolid,
  faCheck as fasCheck,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTerminal,
  faTriangleExclamation,
  faXmark as faXmarkSolid,
} from "@fortawesome/pro-solid-svg-icons"
// Custom Icons
import {
  facBoxUnarchive,
  facDanishKroneSign,
  facHongKongDollarSign,
  facKrSign,
  facLadder,
  facRomanianLeuSign,
  facShortText,
  facSidebarFlip,
  facSingaporeDollarSign,
  facSquareBrazilianRealSign,
  facSquareChfSign,
  facSquareDanishKroneSign,
  facSquareDollarSign,
  facSquareEuroSign,
  facSquareHongKongDollarSign,
  facSquareIndianRupeeSign,
  facSquareKrSign,
  facSquarePhilippinePesoSign,
  facSquarePoundSign,
  facSquareRomanianLeuSign,
  facSquareSingaporeDollarSign,
  facSquareSouthAfricanRandSign,
  facSquareThaiBahtSign,
  facSquareTurkishLiraSign,
  facSquareUaeDirhamSign,
  facSquareUaeDirhamTextSign,
  facSquareYenSign,
  facUaeDirhamSign,
  facUsers,
} from "icons"

// Disable autoAddCSS so we can avoid using style-src: unsafe-inline in
// our Content-Security-Policy header.
config.autoAddCss = false

library.add(
  faAnalytics,
  faAnalytics2,
  faAngleLeft,
  faArrowAltRightSolid,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowUp,
  faArrowUpRightFromSquare,
  faBahtSign,
  faBan,
  faBars,
  faBehance,
  faBloggerB,
  faBolt,
  faBoltSlash,
  faBoxArchive,
  faBrazilianRealSign,
  faBriefcase,
  faBuilding,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  facBoxUnarchive,
  faChartBar,
  faChartPie,
  faCheck,
  faChevronsLeft,
  faChevronsRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChfSign,
  faChairOffice,
  faCircleCheck,
  faCircleCheckDuo,
  faCircleCheckRegular,
  faCircleExclamation,
  faCircleExclamationDuo,
  faCircleExclamationRegular,
  faCircleInfo,
  faCircleInfoDuo,
  faCircleInfoRegular,
  faCircleMinus,
  faCircleMinusDuo,
  faCircleXmark,
  faCircleXmarkDuo,
  faClipboardCheck,
  faClock,
  faClone,
  faCloneLight,
  faCodeFork,
  faCodeMerge,
  faComment,
  faCommentSolid,
  faComments,
  faCompass,
  faDesktop,
  faDollarSign,
  faDownload,
  faDownloadLight,
  faDribbble,
  faDropbox,
  faDropletSlash,
  faEdit,
  faEllipsis,
  faEllipsisRegular,
  faEllipsisVertical,
  faEnvelope,
  faEraser,
  faEuroSign,
  faExclamation,
  faEye,
  faEyeSlash,
  faEyeSolid,
  faFacebookF,
  faFile,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFillDrip,
  faFilter,
  faFlag,
  faFolderTree,
  faGear,
  faGithub,
  faGripDotsVertical,
  faHashtag,
  faHeart,
  faHistory,
  faHome,
  faHubspot,
  faICursor,
  faIdBadge,
  faIndianRupeeSign,
  faInstagram,
  faKey,
  faLayerGroup,
  faLink,
  faLinkedinIn,
  faListOl,
  faListTree,
  faListUl,
  faLockKeyhole,
  faLongArrowAltRight,
  faLowVision,
  faMediumM,
  faMessageLines,
  faMinus,
  faMoneyBill,
  faPalette,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencilRuler,
  faPercent,
  faPesoSign,
  faPhone,
  faPhoneSolid,
  faPinterest,
  faPlus,
  faPoundSign,
  faPrint,
  faR,
  faRectangleHistoryCirclePlus,
  faRedoAlt,
  faRight,
  faRotate,
  fasCheck,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faShareSquare,
  faSignature,
  faSignIn,
  faSignOut,
  faSitemap,
  faSliders,
  faSnapchatGhost,
  faSquareCheck,
  faSquarePlus,
  faSort,
  faSortDown,
  faSortUp,
  faSparkles,
  faStar,
  faStarLight,
  faStickyNote,
  faStopwatch,
  faTerminal,
  faTrashAlt,
  faTriangleExclamation,
  faTriangleExclamationDuo,
  faTriangleExclamationRegular,
  faTurkishLira,
  faTwitter,
  faUndo,
  faUndoAlt,
  faUpload,
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowRightArrowLeft,
  faUsdSquare,
  faUser,
  faUserPlus,
  faUserPlusLight,
  faUsers,
  faVimeoV,
  faWordpressSimple,
  faXmark,
  faXmarkSolid,
  faXmarkLight,
  faYenSign,
  faYoutube,
  facDanishKroneSign,
  facHongKongDollarSign,
  facKrSign,
  facLadder,
  facRomanianLeuSign,
  facSidebarFlip,
  facSingaporeDollarSign,
  facSquareBrazilianRealSign,
  facSquareChfSign,
  facSquareDanishKroneSign,
  facSquareDollarSign,
  facSquareEuroSign,
  facSquareHongKongDollarSign,
  facSquareIndianRupeeSign,
  facSquareKrSign,
  facSquarePoundSign,
  facSquareRomanianLeuSign,
  facSquareSingaporeDollarSign,
  facSquareYenSign,
  facSquareSouthAfricanRandSign,
  facSquarePhilippinePesoSign,
  facSquareThaiBahtSign,
  facSquareTurkishLiraSign,
  facUaeDirhamSign,
  facSquareUaeDirhamSign,
  facSquareUaeDirhamTextSign,
  facShortText,
  facUsers,
)

const FontAwesomeStub = {
  config,
  dom,
  library,
  mount: () => {
    const $ = window.jQuery
    $(() => dom.watch())
  },
}

export { FontAwesomeStub }
